import {
  useEffect,
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import { Button, message } from "antd";
import { getProfile } from "../../../../../services/services.profile";
import {
  createConnectRiskAssessment,
  createNetworkRiskAssessment,
  getConnectionData,
  getNetworkData,
  updateRequestConnection,
  updateUserNetwork,
} from "../../../../../services/customer-ai-viewer";
import { duration } from "moment";

const RiskAssessementButton = forwardRef(
  (
    {
      setIsOpenTranslate,
      type,
      apiId,
      fetchConnectionData,
      setAIAssessmentLoading,
      riskLevel = false,
      aiObservations = "",
    },
    ref
  ) => {
    const [AIAssessmentloading, setAIAssessmentloading] = useState(false);
    const [status, setStatus] = useState("");

    const intervalRef = useRef(null);

    const signalRef = useRef(null);

    useEffect(() => {
      setAIAssessmentLoading?.(AIAssessmentloading);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [AIAssessmentloading]);

    const createAiRiskAssessment = (language, languageLabel) => {
      signalRef.current = new AbortController();
      const signal = signalRef.current.signal;

      setAIAssessmentloading(true);

      getProfile().then((user) => {
        if (
          user?.riskAssessmentLimit <= 0 ||
          user?.riskAssessmentLimit === null
        ) {
          setAIAssessmentloading(false);
          return message.info(
            "You've reached the limit of generating reports per month, please try later."
          );
        } else {
          apiCall();
        }
      });

      const apiCall = () => {
        const payload = {
          ...(type === "connection" && {
            reqConnectionId: apiId,
            lang: language,
            langName: languageLabel,
          }),
          ...(type === "network" && {
            networkId: apiId,
            lang: language,
            langName: languageLabel,
          }),
        };

        (type === "connection"
          ? createConnectRiskAssessment(payload, signal)
          : createNetworkRiskAssessment(payload, signal)
        )
          .then((res) => {
            if (res?.error) {
              setAIAssessmentloading(false);
              updateMessageSeenStatus();
              return message.error(
                "Your Create AI Risk Assessment Report request failed due to something went wrong on the server side, please try again.",
                duration(10)
              );
            }
            setStatus("success");
            updateMessageSeenStatus();
            message.success(
              "Requested AI Risk Assessment Report generated successfully"
            );
            // Fetch Connection Details
            fetchConnectionData();
          })
          .finally(() => {
            setAIAssessmentloading(false);
          });
      };
    };

    const updateMessageSeenStatus = () => {
      type === "connection"
        ? updateRequestConnection({
            reqConnectionId: apiId,
            reportMessageSeen: true,
          })
        : updateUserNetwork({
            userNetworkId: apiId,
            reportMessageSeen: true,
          });
    };

    const fetchConnectionApi = (signal) => {
      getConnectionData(apiId, signal).then((res) => {
        if (res?.error) {
          clearInterval(intervalRef.current);
          setAIAssessmentloading(false);
        } else if (
          res.requestConnectionData.riskAssessReportStatus === "failure"
        ) {
          clearInterval(intervalRef.current);
          setAIAssessmentloading(false);
          if (!res.requestConnectionData.reportMessageSeen) {
            message.error(
              "Your Create AI Risk Assessment Report request failed due to something went wrong on the server side, please try again.",
              duration(10)
            );
            updateMessageSeenStatus();
          }
        } else if (
          res.requestConnectionData.riskAssessReportStatus === "success"
        ) {
          setStatus("success");
          if (!res.requestConnectionData.reportMessageSeen) {
            message.success(
              "Requested AI Risk Assessment Report generated successfully"
            );
            updateMessageSeenStatus();
            fetchConnectionData();
          }
          clearInterval(intervalRef.current);
          setAIAssessmentloading(false);
        } else if (
          res.requestConnectionData.riskAssessReportStatus === "" ||
          !res.requestConnectionData.riskAssessReportStatus
        ) {
          clearInterval(intervalRef.current);
          setAIAssessmentloading(false);
        }
      });
    };

    const fetchNetworkApi = () => {
      getNetworkData(apiId).then((res) => {
        if (res?.error) {
          clearInterval(intervalRef.current);
          setAIAssessmentloading(false);
        } else if (res.usersNetworkData.riskAssessReportStatus === "failure") {
          clearInterval(intervalRef.current);
          setAIAssessmentloading(false);
          if (!res.usersNetworkData.reportMessageSeen) {
            message.error(
              "Your Create AI Risk Assessment Report request failed due to something went wrong on the server side, please try again.",
              duration(10)
            );
            updateMessageSeenStatus();
          }
        } else if (res.usersNetworkData.riskAssessReportStatus === "success") {
          setStatus("success");
          if (!res.usersNetworkData.reportMessageSeen) {
            message.success(
              "Requested AI Risk Assessment Report generated successfully"
            );
            updateMessageSeenStatus();
            fetchConnectionData();
          }
          clearInterval(intervalRef.current);
          setAIAssessmentloading(false);

          // fetch connection details
        } else if (
          res.usersNetworkData.riskAssessReportStatus === "" ||
          !res.usersNetworkData.riskAssessReportStatus
        ) {
          clearInterval(intervalRef.current);
          setAIAssessmentloading(false);
        }
      });
    };

    useEffect(() => {
      intervalRef.current = setInterval(
        type === "connection" ? fetchConnectionApi : fetchNetworkApi,
        5000
      );
      return () => clearInterval(intervalRef.current);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      setAIAssessmentloading(true);
      type === "connection" ? fetchConnectionApi() : fetchNetworkApi();
      return () => {
        signalRef.current?.abort(); // Abort request when component unmounts
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Expose the method
    useImperativeHandle(ref, () => ({
      createAiRiskAssessment: createAiRiskAssessment,
    }));

    return (
      <>
        <div
          onClick={() => {
            !AIAssessmentloading && !aiObservations
              ? message.error(
                  "To proceed with the Step 3 first complete the Step 1"
                )
              : !riskLevel
              ? message.error(
                  "To proceed with the Step 3 first complete the Step 2"
                )
              : setIsOpenTranslate(true);
          }}
        >
          {AIAssessmentloading ? "In Progress" : "Create"}
        </div>
      </>
    );
  }
);

export default RiskAssessementButton;
