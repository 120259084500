import React, { useState } from "react";
import { Drawer, message } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import "./index.css";
import { baseUrl } from "../../../../services/services.profile";
import { HiOutlineTrash } from "react-icons/hi";
import axios from "axios";
import { PDFDownloadLink } from "@react-pdf/renderer";
import NetworkRiskPDFFile from "../../../modal/NetworkRiskPDFFile";
import PDFFile from "../../../PDFFile";

const ViewRiskAssessmentReport = ({
  reportDrawerOpen,
  reportDrawerClose,
  dataDetail,
  riskReports,
  docList,
  filterList,
  dataFilter,
  getData,
  isMerging,
  handleDownloadClick,
  sortedArray,
  countryCode,
  type,
  userInfo,
}) => {
  const viewPDF = (item) => {
    window.open(baseUrl + "/" + item, "_blank");
  };

  const getRiskAssessmentReportFileName = (item) => {
    const chunks = item.filename.split("_");
    const languageCode = chunks[chunks.length - 1];
    return `Risk Assessment Report_${new Date(item.createdAt)
      .toString()
      .slice(3, 21)}_${languageCode}`;
  };

  const deletePDF = async (item) => {
    const payloadUrl =
      type === "network"
        ? "/api/network/networkToDeletePDF"
        : type === "connect" && "/api/connect/deletePDF";

    const res = await axios
      .post(baseUrl + payloadUrl, {
        [type === "network" ? "network_id" : type === "connect" && "req_id"]:
          dataDetail?._id,
        name: item?.filename,
        _id: item?._id,
      })
      .then((res) => {
        message.success(res?.data?.message);
        if (filterList.length > 0) {
          dataFilter();
        } else {
          getData();
        }
      })
      .catch((err) => {
        message.error(res?.data?.error);
      });
  };

  const inspectionReportData =
    type === "network"
      ? dataDetail?.user_id?.InspectRp2 ?? []
      : type === "connect"
      ? userInfo?.InspectRp2 ?? []
      : [];

  const commentsReportDocumentData =
    type === "network" ? (
      <NetworkRiskPDFFile
        data={sortedArray}
        userinfo={dataDetail}
        countryCode={countryCode}
        networkDetail={dataDetail}
        docList={docList}
      />
    ) : (
      type === "connect" && (
        <PDFFile
          data={sortedArray}
          userinfo={userInfo}
          connectCountryCode={countryCode}
          connectionDetail={dataDetail}
          docList={docList}
        />
      )
    );

  const supportingDocumentsData =
    type === "network" ? dataDetail : type === "connect" && userInfo;

  return (
    <Drawer
      placement="right"
      title={
        <div className="flex justify-between items-center pt-2 px-5">
          <p className="text-xl font-bold">Reports & Supporting Documents</p>
          <CloseOutlined
            className="!text-lg"
            onClick={() => reportDrawerClose()}
          />
        </div>
      }
      className="viewDocumentsDrawer"
      closable={false}
      onClose={() => reportDrawerClose()}
      open={reportDrawerOpen}
    >
      <div className="flex flex-col w-full py-3 px-2 lg:flex-row gap-5 flex-1">
        <div className="w-full grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 xl:gap-5 gap-4 pb-2">
          <div className="bg-[#fff] p-5 w-full flex flex-col gap-5 rounded-xl shadow-md">
            <div className="flex flex-col gap-3">
              <p className="font-bold">Inspection Report</p>

              {inspectionReportData?.length > 0 && (
                <div className="flex flex-col gap-2">
                  {inspectionReportData?.reverse()?.map((item, index) => (
                    <div
                      className="flex justify-between items-center w-full"
                      key={index}
                    >
                      <p className="text-primary underline cursor-pointer text-sm break-words overflow-ellipsis overflow-hidden">
                        <a
                          href={`https://api.kyb.pro/${item.fileUrl}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          IR reports as on{" "}
                          {new Date(item.createdAt).toString().slice(3, 21)}
                        </a>
                      </p>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
          <div className="bg-[#fff] p-5 w-full flex flex-col gap-5 rounded-xl shadow-md">
            <div className="flex flex-col gap-3">
              <p className="font-bold">Supporting Document</p>

              {docList?.length > 0 && (
                <div className="flex justify-between flex-col items-center gap-2">
                  {docList?.length !== 0 &&
                    docList?.map((item, index) => (
                      <div
                        className="flex gap-1 justify-between items-center w-full"
                        key={index}
                      >
                        <p
                          className="text-primary underline cursor-pointer text-sm break-words overflow-ellipsis overflow-hidden"
                          onClick={() => viewPDF(item?.filename)}
                        >
                          {item?.filename}
                        </p>
                        <div>
                          <HiOutlineTrash
                            className="text-primary cursor-pointer w-5 h-5"
                            onClick={() => deletePDF(item)}
                          />
                        </div>
                      </div>
                    ))}
                </div>
              )}
            </div>
          </div>
          <div className="bg-[#fff] p-5 w-full flex flex-col gap-5 rounded-xl shadow-md">
            <div className="flex flex-col gap-3">
              <p className="font-bold">Risk Assessment Report</p>

              {riskReports?.length > 0 && (
                <div className="flex justify-between flex-col items-center gap-2">
                  {riskReports?.length !== 0 &&
                    riskReports?.map((item, index) => (
                      <div
                        className="flex justify-between items-center w-full"
                        key={`risk_${index}`}
                      >
                        <p
                          className="text-primary underline cursor-pointer text-sm break-words overflow-ellipsis overflow-hidden"
                          onClick={() => viewPDF(item?.filename)}
                        >
                          {getRiskAssessmentReportFileName(item)}
                        </p>
                      </div>
                    ))}
                </div>
              )}
            </div>
          </div>
          <div className="bg-[#fff] p-5 w-full flex gap-5 rounded-xl shadow-md items-center justify-start">
            <PDFDownloadLink
              document={commentsReportDocumentData}
              fileName="RiskAssessmentReport.pdf"
            >
              <button
                type="button"
                className="text-[13px] underline break-words tracking-wide text-primary w-auto flex items-center text-left cursor-pointer rounded-lg"
              >
                Download comments report
              </button>
            </PDFDownloadLink>
          </div>
          <div className="bg-[#fff] p-5 w-full flex gap-5 rounded-xl shadow-md items-center justify-start">
            <button
              type="button"
              className="text-[13px] underline break-words tracking-wide text-primary w-auto flex items-center text-left cursor-pointer rounded-lg"
              onClick={() => handleDownloadClick(supportingDocumentsData)}
              disabled={isMerging}
            >
              Download supporting documents
            </button>
          </div>
        </div>
      </div>
    </Drawer>
  );
};

export default ViewRiskAssessmentReport;
