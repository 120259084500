import { Layout, Spin } from "antd";
import { Content } from "antd/lib/layout/layout";
import React, { useEffect, useRef, useState } from "react";
import { AiOutlineHome, AiOutlineUser } from "react-icons/ai";
import { useNavigate, useRoutes } from "react-router";
import { Navigate, Routes, Route } from "react-router-dom";
import "./App.css";
import Sidebar from "./components/Sidebar";
import Header from "./components/Header";
import ArtpassID from "./pages/Artpass";
import Profile from "./pages/Profile";
import RequestIDVCDD from "./pages/RequestIDVCDD";
import ConnectionsRequest from "./pages/ConnectionsRequest";
import ConnectionsCode from "./pages/ConnectionsCode";
import Settings from "./pages/Settings";
import Pricing from "./pages/Pricing";
import Connections from "./pages/Connections";
import ConnectionsRequests from "./pages/ConnectionsRequests";
import Membership from "./pages/Membership";
import Logout from "./pages/Logout";
import { SITENAME, getProfile } from "./services/services.profile";
import ConnectionsOnboarding from "./pages/ConnectionsOnboarding";
import Login from "./pages/Login";
import Personal from "./pages/Personal";
import Business from "./pages/Business";
import Compliance from "./pages/Compliance";
import PendingWork from "./pages/PendingWork";
import Health from "./pages/Health";
import TeamMember from "./pages/TeamMember";
import SecurityRoles from "./pages/SecurityRoles";
import RiskReports from "./pages/RiskReports";
import Training from "./pages/Training";
import TeamPortal from "./pages/TeamPortal";
import Promo from "./pages/Promo";
import { UserProvider } from "./contexts/userContext";
import AIAssistance from "./pages/AIAssistance";

export const RoleManage = {
  ADMIN: "ADMIN",
  MLRO: "MLRO",
  SECURITY_ROLE: "SECURITY_ROLE",
  TEAM_MEMBER: "TEAM_MEMBER",
  SALES_TEAM_MEMBER: "SALES_TEAM_MEMBER",
  NEW_ADMIN: "NEW_ADMIN",
  NEW_MLRO: "NEW_MLRO",
};

const mlro = [
  {
    icon: <AiOutlineHome />,
    label: "Home",
    path: "",
    children: [
      {
        label: "DASHBOARD",
        path: "/",
        children: [
          {
            label: "Health",
            path: "/health",
            element: <Health />,
          },
          {
            label: "Pending Work",
            path: "/dashboard/pending-work",
            element: <PendingWork />,
          },
          // {
          //   label: "Activity Log",
          //   path: "/dashboard-health/activity-log",
          //   element: <ActivityLog />,
          // },
        ],
      },
      {
        label: "TEAM PORTAL",
        path: "/",
        children: [
          {
            label: "Team Members",
            path: "/team-member",
            element: <TeamMember />,
          },
          {
            label: "Security Roles",
            path: "/team-portal/security-roles",
            element: <SecurityRoles />,
          },
          // {
          //   label: "To Do",
          //   path: "/team-member/to-do",
          //   element: <TeamPortal />,
          // },
          {
            label: "Risk Reports",
            path: "/team-portal/risk-reports",
            element: <RiskReports />,
          },
          {
            label: "Training",
            path: "/team-portal/training",
            element: <Training />,
          },
        ],
      },
      {
        label: "CUSTOMER PORTAL",
        path: "/",
        children: [
          {
            label: "Whitelisted",
            path: "/whitelisted",
            element: <Connections />,
          },
          {
            label: "Pending Whitelist",
            path: "/custom-portal/requests",
            element: <ConnectionsRequests />,
          },
          {
            label: "Onboarding In Progress",
            path: "/custom-portal/onboarding",
            element: <ConnectionsOnboarding />,
          },
          {
            label: "Onboarding Link",
            path: "/custom-portal/request-idv-and-cdd",
            element: <RequestIDVCDD />,
          },
          {
            label: "Request To Connect",
            path: "/custom-portal/request",
            element: <ConnectionsRequest />,
          },
          {
            label: "Connection Codes",
            path: "/custom-portal/code",
            element: <ConnectionsCode />,
          },
        ],
      },

      {
        label: "SUPPORT PORTAL",
        path: "/",
        children: [
          {
            label: "Support",
            path: "/support",
          },
        ],
      },
    ],
  },
  {
    icon: <AiOutlineUser />,
    label: "User",
    path: "/user",
    children: [
      {
        label: "Profile",
        path: "/user/profile",
        element: <Profile />,
      },
      {
        label: `My ${SITENAME}`,
        path: "/user/juste-id",
        element: <ArtpassID />,
      },
      {
        label: "Logout",
        path: "/user/logout",
        element: <Logout />,
      },
    ],
  },
];

const links1 = [
  {
    icon: <AiOutlineHome />,
    label: "Home",
    path: "",
    children: [
      {
        label: "DASHBOARD",
        path: "/",
        children: [
          {
            label: "Health",
            path: "/health",
            element: <Health />,
          },
          {
            label: "Pending Work",
            path: "/dashboard/pending-work",
            element: <PendingWork />,
          },
          // {
          //   label: "Activity Log",
          //   path: "/dashboard-health/activity-log",
          //   element: <ActivityLog />,
          // },
        ],
      },
      {
        label: "TEAM PORTAL",
        path: "/",
        children: [
          {
            label: "Team Members",
            path: "/team-member",
            element: <TeamMember />,
          },
          {
            label: "Security Roles",
            path: "/team-portal/security-roles",
            element: <SecurityRoles />,
          },
          {
            label: "To Do",
            path: "/team-portal/to-do",
            element: <TeamPortal />,
          },
          {
            label: "Risk Reports",
            path: "/team-portal/risk-reports",
            element: <RiskReports />,
          },
          {
            label: "Training",
            path: "/team-portal/training",
            element: <Training />,
          },
        ],
      },
      {
        label: "CUSTOMER PORTAL",
        path: "/",
        children: [
          {
            label: "Whitelisted",
            path: "/whitelisted",
            element: <Connections />,
          },
          {
            label: "Pending Whitelist",
            path: "/custom-portal/requests",
            element: <ConnectionsRequests />,
          },
          {
            label: "Onboarding In Progress",
            path: "/custom-portal/onboarding",
            element: <ConnectionsOnboarding />,
          },
          {
            label: "Onboarding Link",
            path: "/custom-portal/request-idv-and-cdd",
            element: <RequestIDVCDD />,
          },
          {
            label: "Request To Connect",
            path: "/custom-portal/request",
            element: <ConnectionsRequest />,
          },
          {
            label: "Connection Codes",
            path: "/custom-portal/code",
            element: <ConnectionsCode />,
          },
        ],
      },
      {
        label: "SUPPORT PORTAL",
        path: "/",
        children: [
          {
            label: "Support",
            path: "/support",
          },
        ],
      },
    ],
  },
  {
    icon: <AiOutlineUser />,
    label: "User",
    path: "/user",
    children: [
      {
        label: "Profile",
        path: "/user/profile",
        element: <Profile />,
      },
      {
        label: "Logout",
        path: "/user/logout",
        element: <Logout />,
      },
    ],
  },
];

const links2 = [
  {
    icon: <AiOutlineHome />,
    label: "Home",
    path: "",
    children: [
      {
        label: "To Do",
        path: "/to-do",
        element: <TeamPortal />,
      },
      {
        label: "Risk Reports",
        path: "/risk-reports",
        element: <RiskReports />,
      },
      {
        label: "Training",
        path: "/training",
        element: <Training />,
      },
    ],
  },
  {
    icon: <AiOutlineUser />,
    label: "User",
    path: "/user",
    children: [
      {
        label: "Profile",
        path: "/user/profile",
        element: <Profile />,
      },
      // {
      //   label: `My ${SITENAME}`,
      //   path: "/user/juste-id",
      //   element: <ArtpassID />,
      // },
      {
        label: "Logout",
        path: "/user/logout",
        element: <Logout />,
      },
    ],
  },
];

const links3 = [
  {
    icon: <AiOutlineHome />,
    label: "Home",
    path: "",
    children: [
      {
        label: "To Do",
        path: "/to-do",
        element: <TeamPortal />,
      },
      {
        label: "Risk Reports",
        path: "/risk-reports",
        element: <RiskReports />,
      },
      {
        label: "Training",
        path: "/training",
        element: <Training />,
      },
      {
        label: "CUSTOMER PORTAL",
        path: "/",
        children: [
          {
            label: "Whitelisted",
            path: "/whitelisted",
            element: <Connections />,
          },
          {
            label: "Pending Whitelist",
            path: "/custom-portal/requests",
            element: <ConnectionsRequests />,
          },
          {
            label: "Onboarding In Progress",
            path: "/custom-portal/onboarding",
            element: <ConnectionsOnboarding />,
          },
          {
            label: "Request To Connect",
            path: "/custom-portal/request",
            element: <ConnectionsRequest />,
          },
          {
            label: "Connection Codes",
            path: "/custom-portal/code",
            element: <ConnectionsCode />,
          },
        ],
      },
    ],
  },
  {
    icon: <AiOutlineUser />,
    label: "User",
    path: "/user",
    children: [
      {
        label: "Profile",
        path: "/user/profile",
        element: <Profile />,
      },
      // {
      //   label: `My ${SITENAME}`,
      //   path: "/user/juste-id",
      //   element: <ArtpassID />,
      // },

      {
        label: "Logout",
        path: "/user/logout",
        element: <Logout />,
      },
    ],
  },
];

const admin = [
  {
    icon: <AiOutlineHome />,
    label: "Home",
    path: "",
    children: [
      {
        label: "DASHBOARD",
        path: "/",
        children: [
          {
            label: "Health",
            path: "/health",
            element: <Health />,
          },
          {
            label: "Pending Work",
            path: "/dashboard/pending-work",
            element: <PendingWork />,
          },
          // {
          //   label: "Activity Log",
          //   path: "/dashboard-health/activity-log",
          //   element: <ActivityLog />,
          // },
        ],
      },
      {
        label: "TEAM PORTAL",
        path: "/",
        children: [
          {
            label: "Team Members",
            path: "/team-member",
            element: <TeamMember />,
          },
          {
            label: "Security Roles",
            path: "/team-portal/security-roles",
            element: <SecurityRoles />,
          },
          {
            label: "To Do",
            path: "/team-portal/to-do",
            element: <TeamPortal />,
          },
          {
            label: "Risk Reports",
            path: "/team-portal/risk-reports",
            element: <RiskReports />,
          },
          {
            label: "Training",
            path: "/team-portal/training",
            element: <Training />,
          },
        ],
      },
      {
        label: "CUSTOMER PORTAL",
        path: "/",
        children: [
          {
            label: "Whitelisted",
            path: "/whitelisted",
            element: <Connections />,
          },
          {
            label: "Pending Whitelist",
            path: "/custom-portal/requests",
            element: <ConnectionsRequests />,
          },
          {
            label: "Onboarding In Progress",
            path: "/custom-portal/onboarding",
            element: <ConnectionsOnboarding />,
          },
          {
            label: "Onboarding Link",
            path: "/custom-portal/request-idv-and-cdd",
            element: <RequestIDVCDD />,
          },
          {
            label: "Request To Connect",
            path: "/custom-portal/request",
            element: <ConnectionsRequest />,
          },
          {
            label: "Connection Codes",
            path: "/custom-portal/code",
            element: <ConnectionsCode />,
          },
        ],
      },
      {
        label: "SUPPORT PORTAL",
        path: "/",
        children: [
          {
            label: "Support",
            path: "/support",
          },
        ],
      },
    ],
  },
  {
    icon: <AiOutlineUser />,
    label: "User",
    path: "/user",
    children: [
      {
        label: "Profile",
        path: "/user/profile",
        element: <Profile />,
      },
      {
        label: `My ${SITENAME}`,
        path: "/user/juste-id",
        element: <ArtpassID />,
      },
      {
        label: "Settings",
        path: "/user/settings",
        element: <Settings />,
      },
      {
        label: "Pricing",
        path: "/user/pricing",
        element: <Pricing />,
      },
      {
        label: "Membership",
        path: "/user/membership",
        element: <Membership />,
      },
      {
        label: "Logout",
        path: "/user/logout",
        element: <Logout />,
      },
    ],
  },
];

const old_admin = [
  {
    icon: <AiOutlineHome />,
    label: "Home",
    path: "",
    children: [
      {
        label: `My ${SITENAME}`,
        path: "/user/juste-id",
        element: <ArtpassID />,
      },
      {
        label: "Request KYC",
        path: "/",
        children: [
          {
            label: "New Onboarding",
            path: "/request-idv-and-cdd",
            element: <RequestIDVCDD />,
          },
          {
            label: "Request To Connect",
            path: "/request-idv-and-cdd/request",
            element: <ConnectionsRequest />,
          },
          {
            label: "Connection Codes",
            path: "/request-idv-and-cdd/code",
            element: <ConnectionsCode />,
          },
        ],
      },
      {
        label: "Connections",
        path: "/",
        children: [
          {
            label: "Whitelisted",
            path: "/connections",
            element: <Connections />,
          },
          {
            label: "Pending Whitelist",
            path: "/connections/requests",
            element: <ConnectionsRequests />,
          },
          {
            label: "Onboarding In Progress",
            path: "/connections/onboarding",
            element: <ConnectionsOnboarding />,
          },
        ],
      },
      {
        label: "Support",
        path: "/support",
      },
    ],
  },
  {
    icon: <AiOutlineUser />,
    label: "User",
    path: "/user",
    children: [
      {
        label: "Profile",
        path: "/user/profile",
        element: <Profile />,
      },
      {
        label: "Settings",
        path: "/user/settings",
        element: <Settings />,
      },
      {
        label: "Pricing",
        path: "/user/pricing",
        element: <Pricing />,
      },
      {
        label: "Membership",
        path: "/user/membership",
        element: <Membership />,
      },
      {
        label: "Logout",
        path: "/user/logout",
        element: <Logout />,
      },
    ],
  },
];

const patron = [
  {
    icon: <AiOutlineHome />,
    label: "Home",
    path: "",
    children: [
      {
        label: `My ${SITENAME}`,
        path: "/user/juste-id",
        element: <ArtpassID />,
      },
      {
        label: "Connections",
        path: "/",
        children: [
          {
            label: "Whitelisted",
            path: "/connections",
            element: <Connections />,
          },
          {
            label: "Pending Whitelist",
            path: "/connections/requests",
            element: <ConnectionsRequests />,
          },
          // {
          //   label: "Onboarding In Progress",
          //   path: "/connections/onboarding",
          //   element: <ConnectionsOnboarding />,
          // },
        ],
      },
      {
        label: "Support",
        path: "/support",
      },
    ],
  },
  {
    icon: <AiOutlineUser />,
    label: "User",
    path: "/user",
    children: [
      {
        label: "Profile",
        path: "/user/profile",
        element: <Profile />,
      },
      {
        label: "Logout",
        path: "/user/logout",
        element: <Logout />,
      },
    ],
  },
];

const new_user = [
  {
    icon: <AiOutlineHome />,
    label: "Home",
    path: "",
    children: [
      {
        label: "To Do",
        path: "/to-do",
        element: <TeamPortal />,
      },
      {
        label: "Logout",
        path: "/user/logout",
        element: <Logout />,
      },
    ],
  },
];

const App = () => {
  const navigate = useNavigate();
  const [userProfileInfo, setUserProfileInfo] = useState();
  const [collapsed, setCollapsed] = useState(false);
  const [user, setUser] = useState(null);
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [userType, setUserType] = useState(RoleManage.ADMIN);
  const [userAccessType, setUserAccessType] = useState([
    "MLRO",
    "BUSINESS",
    "TEAM_MEMBER",
  ]);
  const sidebarRef = useRef();

  const onBreakPoints = (e) => {
    setCollapsed(e);
  };
  useEffect(() => {
    getProfileData();
  }, []);

  const getProfileData = () => {
    setIsLoading(true);
    getProfile()
      .then((user) => {
        setUserProfileInfo(user);
        // setLocalStorageData("userType", RoleManage.TEAM_MEMBER);
        if (user && user?._id) {
          if (user?.last_working_date) {
            navigate("/user/logout");
          } else {
            if (
              user?.accountType?.type === RoleManage?.TEAM_MEMBER ||
              user?.accountType?.type === RoleManage?.SALES_TEAM_MEMBER ||
              user?.utype === RoleManage?.NEW_ADMIN ||
              user?.utype === RoleManage?.NEW_MLRO
            ) {
              navigate("/to-do");
            } else if (
              user?.accountType?.type === RoleManage?.MLRO ||
              user?.accountType?.type === RoleManage?.SECURITY_ROLE ||
              user?.accountType?.type === RoleManage?.ADMIN
            ) {
              navigate("/health");
            } else {
              navigate("/user/juste-id");
            }

            // If utype NEW_MLRO & NEW_ADMIN and not getting account type then userType is user.utype
            setUserType(
              user?.accountType?.type
                ? user?.accountType?.type
                : user?.utype === RoleManage?.NEW_ADMIN ||
                  user?.utype === RoleManage?.NEW_MLRO
                ? user?.utype
                : RoleManage.ADMIN
            );
            setUser(user);

            if (user._id === "652943c69df2a7301b23c9ab") {
              admin[0].children.push({
                label: "AI ASSISTANCE",
                path: "/AIassistance",
                element: <AIAssistance />,
              });
            }
          }
        } else {
          navigate("/user/logout");
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const linksMap = {
    ADMIN: userAccessType.includes(user?.utype) ? admin : patron,
    MLRO: mlro,
    SECURITY_ROLE: links1,
    TEAM_MEMBER: links2,
    SALES_TEAM_MEMBER: links3,
    NEW_ADMIN: new_user,
    NEW_MLRO: new_user,
  };

  let content = useRoutes(linksMap[userType]);
  return !isLoading ? (
    user ? (
      <>
        <UserProvider
          user={userType}
          RoleManage={RoleManage}
          userData={user}
          sidebarRef={sidebarRef}
        >
          <Layout className="main" hasSider>
            <Layout>
              <Header collapsed={collapsed} setCollapsed={setCollapsed} />
              <Content>{content}</Content>
            </Layout>
            <Sidebar
              ref={sidebarRef}
              collapsed={collapsed}
              links={linksMap[userType]}
              onBreakPoints={onBreakPoints}
            />
          </Layout>
        </UserProvider>
        {/* <Routes>
    <Route path="/user/mem" element={<Membership /> } />
  </Routes> */}
      </>
    ) : (
      // )
      <Layout>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/personal" element={<Personal />} />
          <Route path="/promo1" element={<Promo />} />
          <Route path="/business" element={<Business />} />
          <Route path="/compliance" element={<Compliance />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Layout>
    )
  ) : (
    <div>
      {/* <Spin ></Spin> */}
      <div
        className="flex justify-center items-center"
        style={{ height: "500px" }}
      >
        <Spin
          spinning={isLoading}
          // indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
        />
      </div>
    </div>
  );
};

export default App;
