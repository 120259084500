import React, { useEffect, useRef, useState } from "react";
import { Button, Drawer, Form } from "antd";
import { CloseOutlined, CloudUploadOutlined } from "@ant-design/icons";
import "./index.css";
import { Formik } from "formik";
import * as Yup from "yup";
import { message } from "antd";
import axios from "axios";
import { baseUrl } from "../../../../services/services.profile";

const AddRiskAssessmentReport = ({
  reportDrawerOpen,
  reportDrawerClose,
  dataDetail,
  filterList,
  dataFilter,
  getData,
  type,
}) => {
  const [isLoading, setLoadings] = useState(false);
  const inputRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [uploadReport, setUploadReport] = useState(null);

  const handleUpload = async (values, { resetForm }) => {
    setLoadings(true);
    const formData = new FormData();
    formData.append("pdfFile", values?.file);
    formData.append(
      type === "network" ? "network_id" : type === "connect" && "req_id",
      dataDetail?._id
    );
    const payloadUrl =
      type === "network"
        ? "/api/network/networkToUploadPDF"
        : type === "connect" && "/api/connect/uploadPDF";

    const res = await axios
      .post(baseUrl + payloadUrl, formData)
      .then((res) => {
        resetForm();
        if (filterList.length > 0) {
          dataFilter();
        } else {
          getData();
        }
        message.success(res?.data?.message);
      })
      .catch((err) => {
        message.error(res?.data?.error);
      })
      .finally(() => {
        setLoadings(false);
        reportDrawerClose();
      });
  };

  const validationSchema = Yup.object().shape({
    file: Yup.mixed()
      .required("File is required")
      .test(
        "fileFormat",
        "Only PDF files are allowed",
        (value) => value && value.type === "application/pdf"
      ),
  });

  useEffect(() => {
    if (reportDrawerOpen) {
      setUploadReport(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportDrawerOpen]);

  const onDrop = (event, { setFieldValue }) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    setFieldValue("file", file);
    setUploadReport(file);
    setIsDragging(false);
  };

  return (
    <Formik
      initialValues={{
        file: null,
      }}
      onSubmit={handleUpload}
      validationSchema={validationSchema}
    >
      {({
        values,
        handleSubmit,
        setFieldValue,
        errors,
        touched,
        resetForm,
      }) => (
        <Form layout="vertical">
          <Drawer
            placement="right"
            title={
              <div className="flex justify-between items-center pt-2 px-5">
                <p className="text-xl font-bold">Add Supporting Document</p>
                <CloseOutlined
                  className="!text-lg"
                  onClick={() => {
                    resetForm();
                    reportDrawerClose();
                  }}
                />
              </div>
            }
            className="addSupportingDocuments"
            closable={false}
            footer={
              <div className="flex justify-end items-center gap-3 mt-auto">
                <Button
                  type="primary"
                  ghost
                  onClick={() => {
                    resetForm();
                    reportDrawerClose();
                  }}
                >
                  Cancel
                </Button>
                <Button
                  htmlType="submit"
                  className="flex capitalize"
                  type="primary"
                  loading={isLoading}
                  onClick={handleSubmit}
                >
                  Save
                </Button>
              </div>
            }
            onClose={() => {
              resetForm();
              reportDrawerClose();
            }}
            open={reportDrawerOpen}
          >
            <div className="flex flex-col p-2 pt-0">
              <div className="w-full py-3 px-1 flex flex-col gap-5">
                <div className="bg-[#fff] p-5 flex flex-col gap-5 rounded-xl shadow-md">
                  <div className="flex flex-col gap-1 relative">
                    <div
                      className={`flex flex-col justify-center items-center p-1 border-2 bg-[#f6fafd] rounded-md cursor-pointer ${
                        isDragging
                          ? "bg-[#4fb3ffd0] opacity-30 border-[#4fb3ffd0]"
                          : "border-dashed border-[#ADBCC8]"
                      }`}
                      onClick={() => inputRef.current.click()}
                      onDragOver={(event) => {
                        event.preventDefault();
                        setIsDragging(true);
                      }}
                      onDragLeave={() => {
                        setIsDragging(false);
                      }}
                      onDrop={(event) => onDrop(event, { setFieldValue })}
                    >
                      <input
                        name="file"
                        type="file"
                        accept=".pdf"
                        style={{ display: "none" }}
                        onChange={(event) => {
                          setFieldValue("file", event.target.files[0]);
                          setUploadReport(event.target.files[0]);
                          event.target.value = "";
                        }}
                        ref={inputRef}
                      />
                      <CloudUploadOutlined className="!text-3xl sm:!text-5xl !text-primary" />
                      <p className="text-sm sm:text-xl font-semibold text-primary leading-tight">
                        Upload Supporting Document
                      </p>
                      <span className="text-xs sm:text-base text-primary leading-tight font-semibold">
                        (.pdf format only)
                      </span>
                    </div>
                    {isDragging && (
                      <span className="text-lg font-semibold absolute top-[40%] left-1/3 text-primary">
                        Drop pdf here...
                      </span>
                    )}
                    {uploadReport && (
                      <div className=" items-center gap-1 rounded-md bg-[#c3e4fd6c] py-1 px-2 w-fit grid grid-cols-12">
                        <span className="break-words whitespace-normal col-span-11 text-sm">
                          {uploadReport?.name}
                        </span>
                        <button
                          className="flex items-center justify-end col-span-1"
                          onClick={() => {
                            setFieldValue("file", "");
                            setUploadReport(null);
                          }}
                        >
                          <CloseOutlined />
                        </button>
                      </div>
                    )}
                    {errors.file && touched.file && (
                      <div className="text-red-500">{errors.file}</div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Drawer>
        </Form>
      )}
    </Formik>
  );
};

export default AddRiskAssessmentReport;
