import React, { useEffect, useState } from "react";
import { Drawer, Spin, Tooltip, message } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import "./ManageConnection.css";
import { FiInfo } from "react-icons/fi";
import { Level, Scope } from "../../../TeamMember/CheckBtn";
import ManageConnectionEditDrawer from "../ManageConnectionEditDrawer";
import {
  getConnection,
  requestIdConnection,
  sendConnectionMail,
} from "../../../../services/connection";
import { useUserContext } from "../../../../contexts/userContext";
import moment from "moment";
import { userType } from "../../Constants/UserType.constant";

const MemberEnum = {
  MemberRole: "Edit Role",
  MemberDepartment: "Edit Department",
  MemberScope: "Edit Scope",
  MemberAccountType: "Edit Account Type",
};

let connectionActions = [
  {
    id: 1,
    fieldName1: "Department",
    fieldDetail1: "",
  },
  {
    id: 2,
    fieldName1: "Scope",
    fieldDetail1: <Scope value={"In-scope"} />,
  },
];

const ManageConnection = ({
  manageConnectionDrawerOpen,
  manageConnectionDrawerClose,
  manageConnection,
  connectionType,
  adminDetails,
  getData,
  fetchConnectionData, //This is needed to get ( Network or Connections ) data
}) => {
  const { user, RoleManage } = useUserContext();
  const [memberDetailOpen, setMemberDetailOpen] = useState(false);
  const [memberDetailHeader, setMemberDetailHeader] = useState("");
  const [memberDetailConnection, setMemberDetailConnection] = useState(getData);
  const [isLoading, setIsLoading] = useState(true);

  const memberDetailClose = () => {
    setMemberDetailOpen(false);
  };

  // Actions perform
  const handleAction = (data) => {
    setMemberDetailOpen(true);
    if (data?.fieldName1 === "Scope") {
      setMemberDetailHeader(MemberEnum?.MemberScope);
    } else {
      setMemberDetailHeader(MemberEnum?.MemberDepartment);
    }
  };

  // Get selected connection details
  const getSingleConnection = () => {
    setIsLoading(true);
    getConnection(manageConnection?._id).then((res) => {
      if (!res?.error) {
        const connection = res?.data;
        setData(connection);
      }
      setIsLoading(false);
    });
  };

  const setData = (connection) => {
    if (connection) {
      setMemberDetailConnection(connection);
      connectionActions = [
        {
          id: 1,
          fieldName1: "Department",
          fieldDetail1:
            connection?.department && connection?.department.length > 0
              ? connection?.department
                  .map((res) => res?.department)
                  .join(", ")
                  .toString()
              : "",
        },
        {
          id: 2,
          fieldName1: "Scope",
          fieldDetail1: connection ? (
            connection?.inScope ? (
              <Scope value={"In-scope"} />
            ) : (
              <Level value={"Out-of-scope"} />
            )
          ) : (
            ""
          ),
        },
      ];
    } else {
      connectionActions = [
        {
          id: 1,
          fieldName1: "Department",
          fieldDetail1: "",
        },
        {
          id: 2,
          fieldName1: "Scope",
          fieldDetail1: <Scope value={"In-scope"} />,
        },
      ];
    }
  };

  const checkExpiry = (date) => {
    const cddDate = new Date(date);
    const currentDate = new Date();

    if (currentDate > cddDate) {
      return true;
    } else {
      return false;
    }
  };

  // Use first time get all control list
  useEffect(() => {
    setIsLoading(true);
    if (!memberDetailConnection && user === RoleManage?.MLRO) {
      getSingleConnection();
    } else {
      setData(memberDetailConnection);
      setIsLoading(false);
    }
  }, [manageConnection]);

  const sendMail = () => {
    let recieverId;

    if (connectionType === "network") {
      recieverId = manageConnection?.user_id?._id;
    }

    if (connectionType === "connect") {
      recieverId =
        adminDetails?._id === manageConnection?.sender?._id
          ? manageConnection?.receiver?._id
          : manageConnection?.sender?._id;
    }

    if (recieverId) {
      const payload = { receiverId: recieverId };
      sendConnectionMail(payload).then((res) => {
        if (res?.error) {
          message.error("Expired doc request fail!");
        } else {
          message.success("Expired doc request successfully!");
        }
      });
    } else {
      message.success("Can't request doc!");
    }
  };

  const requestIdApi = () => {
    let recieverId;
    if (connectionType === "network") {
      recieverId = manageConnection?.user_id?._id;
    }

    if (connectionType === "connect") {
      recieverId =
        adminDetails?._id === manageConnection?.sender?._id
          ? manageConnection?.receiver?._id
          : manageConnection?.sender?._id;
    }

    requestIdConnection({
      userId: recieverId,
    })
      .then((res) => {
        if (res?.error) {
          throw res?.error;
        }
        message.success("Request to update an ID document sent successfully");
      })
      .catch(() => {
        message.error(
          "Something went wrong sending the request, please try again later"
        );
      });
  };

  return (
    <>
      <Drawer
        placement="right"
        title={
          <div className="flex justify-between items-center pt-2 px-5">
            <p className="text-xl font-bold">Manage Connection</p>
            <CloseOutlined
              className="!text-lg"
              onClick={() => {
                manageConnectionDrawerClose();
              }}
            />
          </div>
        }
        className="addMember"
        closable={false}
        onClose={() => manageConnectionDrawerClose()}
        open={manageConnectionDrawerOpen}
      >
        <Spin spinning={isLoading}>
          <div className="w-full p-3 flex flex-col gap-5 h-full">
            <div className="bg-[#fff] p-5 w-full flex flex-col gap-5 rounded-xl shadow-md">
              {connectionActions?.map((item, index) => {
                const content =
                  item.fieldName1 === "Scope"
                    ? "<div class='font-thin'>If this team member temporarily is not processing, storing, or accessing any data that impacts your AML program, you can mark them as not-in-scope.</div>"
                    : "";
                return (
                  <div key={index} className="flex justify-between gap-5">
                    <p className="text-[#000] font-bold text-base flex gap-2 items-center">
                      {item?.fieldName1}
                      {item?.fieldName1 === "Scope" && (
                        <span className="text-[#00000096]">
                          <Tooltip
                            color="black"
                            placement="right"
                            overlayStyle={{
                              maxWidth: "400px",
                              fontWeight: "400",
                            }}
                            title={
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: content,
                                }}
                              />
                            }
                          >
                            <FiInfo />
                          </Tooltip>
                        </span>
                      )}
                    </p>
                    <div className="flex gap-5 items-center">
                      {!isLoading ? item?.fieldDetail1 : ""}
                      <button
                        className="text-[#2C6ED2] font-bold text-sm"
                        onClick={() => {
                          // if (
                          //   item?.fieldName1 !== "Scope" &&
                          //   user === RoleManage?.SECURITY_ROLE
                          // ) {
                          //  message.warning(
                          //    `Only ${userType.mlro} can update this information`
                          //  );
                          // } else {
                          //   handleAction(item);
                          // }
                          handleAction(item);
                        }}
                      >
                        {item?.fieldName1 === "Scope" ? "Change" : "Edit"}
                      </button>
                    </div>
                  </div>
                );
              })}
            </div>

            {((connectionType === "network" &&
              checkExpiry(manageConnection?.user_id?.cdd?.validTill)) ||
              (connectionType === "connect" &&
                checkExpiry(
                  adminDetails?._id === manageConnection?.sender?._id
                    ? manageConnection?.receiver?.cdd?.validTill
                    : manageConnection?.sender?.cdd?.validTill
                ))) && (
              <div className="bg-[#fff] p-5 w-full flex flex-col gap-5 rounded-xl shadow-md">
                <div className="flex justify-between gap-5 w-full">
                  <div className="text-[#000] items-center w-full">
                    <span className="font-bold text-base flex justify-start flex-wrap">
                      CDD & Inspection report update
                    </span>
                  </div>
                  <button
                    className="text-[#2C6ED2] font-bold text-sm"
                    onClick={() => sendMail()}
                  >
                    Request
                  </button>
                </div>
              </div>
            )}
            {((connectionType === "network" &&
              checkExpiry(
                moment(
                  manageConnection?.user_id?.amlReport?.appData?.info?.idDocs?.find(
                    (a) => a.idDocType && a.validUntil
                  )?.validUntil
                ).format("DD MMM YYYY")
              )) ||
              (connectionType === "connect" &&
                checkExpiry(
                  adminDetails?._id === manageConnection?.sender?._id
                    ? moment(
                        manageConnection?.receiver?.amlReport?.appData?.info?.idDocs?.find(
                          (a) => a.idDocType && a.validUntil
                        )?.validUntil
                      ).format("DD MMM YYYY")
                    : moment(
                        manageConnection?.sender?.amlReport?.appData?.info?.idDocs?.find(
                          (a) => a.idDocType && a.validUntil
                        )?.validUntil
                      ).format("DD MMM YYYY")
                ))) && (
              <div className="bg-[#fff] p-5 w-full flex flex-col gap-5 rounded-xl shadow-md">
                <div className="flex justify-between gap-5 w-full">
                  <div className="text-[#000] items-center w-full">
                    <span className="font-bold text-base flex justify-start flex-wrap items-center h-full">
                      ID Expired:
                      <span className="text-[#000] ml-1">
                        {connectionType === "connect" &&
                          (adminDetails?._id === manageConnection?.sender?._id
                            ? moment(
                                manageConnection?.receiver?.amlReport?.appData?.info?.idDocs?.find(
                                  (a) => a.idDocType && a.validUntil
                                )?.validUntil
                              ).format("DD MMM YYYY")
                            : moment(
                                manageConnection?.sender?.amlReport?.appData?.info?.idDocs?.find(
                                  (a) => a.idDocType && a.validUntil
                                )?.validUntil
                              ).format("DD MMM YYYY"))}
                        {connectionType === "network" &&
                          moment(
                            manageConnection?.user_id?.amlReport?.appData?.info?.idDocs?.find(
                              (a) => a.idDocType && a.validUntil
                            )?.validUntil
                          ).format("DD MMM YYYY")}
                      </span>
                    </span>
                  </div>
                  <button
                    className="text-[#2C6ED2] font-bold text-sm whitespace-nowrap"
                    onClick={requestIdApi}
                  >
                    Request New ID
                  </button>
                </div>
              </div>
            )}
          </div>
        </Spin>
      </Drawer>
      {memberDetailOpen && (
        <ManageConnectionEditDrawer
          memberDetailOpen={memberDetailOpen}
          memberDetailClose={memberDetailClose}
          memberDetailHeader={memberDetailHeader}
          manageConnection={manageConnection}
          memberDetailConnection={memberDetailConnection}
          getSingleConnection={getSingleConnection}
          fetchConnectionData={fetchConnectionData}
        />
      )}
    </>
  );
};

export default ManageConnection;

{
  /* <Drawer
        placement="right"
        className="manageConnectionDrawer"
        closable={false}
        onClose={() => {
          manageConnectionDrawerClose();
        }}
        visible={manageConnectionDrawerOpen}
      >
        <div className="flex flex-col p-3 h-full">
          <div className="flex justify-between items-center py-2 px-5 mb-2">
            <p className="text-xl font-bold">Manage Connection</p>
            <CloseOutlined
              className="!text-lg"
              onClick={() => {
                manageConnectionDrawerClose();
              }}
            />
          </div>
          <Spin spinning={isLoading}>
            <div className="w-full flex flex-col gap-5 h-full">
              <div className="bg-[#fff] p-5 w-full flex flex-col gap-5 rounded-xl shadow-md">
                {connectionActions?.map((item, index) => {
                  const content =
                    item.fieldName1 === "Scope"
                      ? "<div class='font-thin'>If this team member temporarily is not processing, storing, or accessing any data that impacts your AML program, you can mark them as not-in-scope.</div>"
                      : "";
                  return (
                    <div key={index} className="flex justify-between gap-5">
                      <p className="text-[#000] font-bold text-base flex gap-2 items-center">
                        {item?.fieldName1}
                        {item?.fieldName1 === "Scope" && (
                          <span className="text-[#00000096]">
                            <Tooltip
                              color="black"
                              placement="right"
                              overlayStyle={{
                                maxWidth: "400px",
                                fontWeight: "400",
                              }}
                              title={
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: content,
                                  }}
                                />
                              }
                            >
                              <FiInfo />
                            </Tooltip>
                          </span>
                        )}
                      </p>
                      <div className="flex gap-5 items-center">
                        {!isLoading ? item?.fieldDetail1 : ""}
                        <button
                          className="text-[#2C6ED2] font-bold text-sm"
                          onClick={() => handleAction(item)}
                        >
                          {item?.fieldName1 === "Scope" ? "Change" : "Edit"}
                        </button>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </Spin>
        </div>
      </Drawer> */
}
