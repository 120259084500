import { Divider, Drawer, Tooltip, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { CloseOutlined } from "@ant-design/icons";
import { FormSwitch } from "../../../core/CustomFormFields";
import { FiInfo } from "react-icons/fi";
import "./filterDrawer.css";
import { getTeamMemberDepartment } from "../../../../services/member";
import { Version } from "../../../../App";

import { userType } from "../../Constants/UserType.constant";

const WhitelistedFilterDrawer = ({
  filterDrawerOpen,
  filterDrawerOpenClose,
  filterData,
  setFilterData,
  count,
  setShowFilterList,
  setCount,
  userData,
  setSettingsFilterValueChange,
  setCallFilter,
}) => {
  const [isDataLoading, setDataLoadings] = useState(false);
  const [departmentData, setDepartmentData] = useState([]);

  const risklevel = [
    {
      name: "Low",
      text: "text-[#70ad47]",
      border: "border-[#70ad47]",
    },
    {
      name: "Medium",
      text: "text-[#ffc000] ",
      border: " border-[#ffc000]",
    },
    {
      name: "High",
      text: "text-[#ff0000]",
      border: " border-[#ff0000]",
    },
  ];
  const riskReviewPending = ["Risk Review Pending"];
  const risktype = [
    "Adverse Media",
    "Activity/Funds",
    "Region",
    "Documents",
    "PEP",
  ];
  const expiredDoc = ["Expired ID's", "Expired CDD's", "On Hold"];
  const uType = ["Natural Person", "Legal Entity"];

  useEffect(() => {
    setDataLoadings(true);
    getTeamMemberDepartment()
      .then((res) => {
        setDepartmentData(res.data);
        setDataLoadings(false);
      })
      .catch(() => {
        setDataLoadings(false);
      });
  }, []);

  const handleFilter = (categoryIndex, risk) => {
    setFilterData((prevFilter) => {
      const updatedRisks = { ...prevFilter };

      if (categoryIndex === "department") {
        const department = departmentData[risk];
        if (updatedRisks[categoryIndex]?.includes(department)) {
          updatedRisks[categoryIndex] = updatedRisks[categoryIndex]?.filter(
            (dept) => dept !== department
          );
        } else {
          updatedRisks[categoryIndex] = [
            ...updatedRisks[categoryIndex],
            department,
          ];
        }
      } else if (categoryIndex === "scope") {
        if (updatedRisks[categoryIndex]?.includes(risk)) {
          updatedRisks[categoryIndex] = ["allConnections"];
        } else {
          updatedRisks[categoryIndex] = [risk];
        }
      } else {
        if (risk === "Natural Person" || risk === "Legal Entity") {
          const isSpecialRisk =
            risk === "Natural Person" || risk === "Legal Entity";
          const otherRisk =
            risk === "Natural Person" ? "Legal Entity" : "Natural Person";

          const isOtherRiskSelected =
            updatedRisks[categoryIndex]?.includes(otherRisk);

          if (isSpecialRisk && isOtherRiskSelected) {
            updatedRisks[categoryIndex] = updatedRisks[categoryIndex]?.filter(
              (r) => r !== otherRisk
            );
          }
        }

        if (updatedRisks[categoryIndex]?.includes(risk)) {
          updatedRisks[categoryIndex] = updatedRisks[categoryIndex]?.filter(
            (r) => r !== risk
          );
        } else {
          updatedRisks[categoryIndex] = [
            ...(updatedRisks[categoryIndex] || []),
            risk,
          ];
        }
      }

      return updatedRisks;
    });
    if (categoryIndex === "scope") {
      localStorage.setItem("scopeFilter", risk);
      setSettingsFilterValueChange(true);
    }
  };

  const clearFilter = () => {
    setFilterData((prevState) => ({
      riskLevel: [],
      riskReviewPending: [],
      riskType: [],
      userType: [],
      expiredDoc: [],
      department: [],
      scope: prevState.scope,
    }));
    setCount(0);
  };

  return (
    <>
      <Drawer
        placement="right"
        title={
          <div className="flex justify-end pt-2 px-5">
            <div className="flex gap-6">
              <div className="flex gap-5 items-center">
                {Object?.values(filterData)
                  .flat()
                  .filter((item) => item !== "allConnections").length > 0 && (
                  <div className="flex gap-1 items-center">
                    Result:{" "}
                    <div className={`${count > 0 && "font-semibold"}`}>
                      {count}
                    </div>
                  </div>
                )}
                <button
                  type="button"
                  onClick={clearFilter}
                  className="text-sm border-primary border font-semibold tracking-wide text-primary w-36 px-2 h-9 rounded-lg"
                >
                  Clear Filter
                </button>
                <button
                  type="button"
                  onClick={() => {
                    setShowFilterList(true);
                    setCallFilter(true);
                  }}
                  className="text-sm  bg-primary font-semibold tracking-wide text-white w-36 px-2 h-9 rounded-lg"
                >
                  View
                </button>
              </div>

              <CloseOutlined
                className="!text-lg"
                onClick={() => {
                  filterDrawerOpenClose();
                }}
              />
            </div>
          </div>
        }
        className="CustomeFilterDrawer"
        closable={false}
        onClose={() => {
          filterDrawerOpenClose();
        }}
        open={filterDrawerOpen}
      >
        <div className="flex lg:flex-row flex-col gap-6 lg:h-full h-max">
          <div className="lg:!w-[670px] w-full flex flex-col gap-8">
            <div className="flex flex-col gap-4">
              <div className="font-bold flex items-center ">
                <div>Filter connections on any combination of:</div>
                <div className="text-[#00000096] !ml-1">
                  <Tooltip
                    color="black"
                    placement="bottom"
                    overlayStyle={{
                      maxWidth: "400px",
                      fontWeight: "400",
                    }}
                    title={
                      <div
                        dangerouslySetInnerHTML={{
                          __html: `<div>
                          <div class='font-semibold text-base'>Filter connections on any combination</div>
                          <br /> 
                          <div class='font-thin'>Use the various filter options to narrow down your search based on specific criteria. You can combine filters for <span class='font-semibold'> Risk Level, Risk Type, Connections </span> and <span class='font-semibold'> Department Tags</span></div><br /><div>Each combination of filters will display the number of matching connections visible in the connections screen. The filters are only active when applied and will reset to default once removed by you.</div>
                          </div>`,
                        }}
                      />
                    }
                  >
                    <FiInfo />
                  </Tooltip>
                </div>
              </div>

              <div className="flex gap-3">
                <div className="bg-[#fff] w-full rounded-xl shadow-md p-4 flex gap-4 flex-col">
                  <div className="flex flex-col gap-[10px]">
                    <div className="text-center mb-1 font-bold">Risk level</div>
                    {risklevel.map((dataValue, index) => (
                      <div key={index} className="w-full">
                        <button
                          onClick={() =>
                            handleFilter("riskLevel", dataValue.name)
                          }
                          type="button"
                          className={`text-[13px] border-2 ${
                            dataValue.border ? dataValue.border : "border-black"
                          } ${
                            dataValue.text ? dataValue.text : "text-black"
                          } font-semibold tracking-wid w-full px-5 h-9 rounded-lg relative`}
                        >
                          {filterData?.riskLevel?.includes(dataValue.name) && (
                            <div className="rounded-full bg-primary w-[11px] h-[11px] absolute top-1/2 left-2 transform -translate-y-1/2"></div>
                          )}
                          {dataValue.name === "Region"
                            ? "Country"
                            : dataValue.name}{" "}
                          Risk
                        </button>
                      </div>
                    ))}
                    <Divider className=" !m-0" />

                    {riskReviewPending?.map((dataValue, index) => (
                      <div key={index} className="w-full">
                        <button
                          onClick={() =>
                            handleFilter("riskReviewPending", dataValue)
                          }
                          type="button"
                          className={`text-[13px] border-2 font-semibold tracking-wid w-full px-5 h-9 rounded-lg relative text-black border-black`}
                        >
                          {filterData?.riskReviewPending?.includes(
                            dataValue
                          ) && (
                            <div className="rounded-full bg-primary w-[11px] h-[11px] absolute top-1/2 left-2 transform -translate-y-1/2"></div>
                          )}
                          {dataValue}
                        </button>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="bg-[#fff] w-full rounded-xl shadow-md p-4 flex gap-4 flex-col">
                  <div className="flex flex-col gap-[10px]">
                    <div className="text-center mb-1 font-bold">Risk Type</div>
                    {risktype.map((dataValue, index) => (
                      <div key={index} className="w-full">
                        <button
                          onClick={() => handleFilter("riskType", dataValue)}
                          type="button"
                          className={`text-[13px] border-2  font-semibold tracking-wid w-full px-5 h-9 rounded-lg relative border-black text-black`}
                        >
                          {filterData?.riskType?.includes(dataValue) && (
                            <div className="rounded-full bg-primary w-[11px] h-[11px] absolute top-1/2 left-2 transform -translate-y-1/2"></div>
                          )}
                          {dataValue === "Region" ? "Country" : dataValue}
                        </button>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="bg-[#fff] w-full rounded-xl shadow-md p-4 flex gap-4 flex-col">
                  <div className="flex flex-col gap-[10px]">
                    <div className="text-center mb-1 font-bold">
                      Connections
                    </div>
                    {uType.map((dataValue, index) => (
                      <div key={index} className="w-full">
                        <button
                          onClick={() => handleFilter("userType", dataValue)}
                          type="button"
                          className={`text-[13px] border-2  font-semibold tracking-wid w-full px-5 h-9 rounded-lg relative border-black text-black`}
                        >
                          {filterData?.userType?.includes(dataValue) && (
                            <div className="rounded-full bg-primary w-[11px] h-[11px] absolute top-1/2 left-2 transform -translate-y-1/2"></div>
                          )}
                          {dataValue}
                        </button>
                      </div>
                    ))}
                    <Divider className=" !m-0" />
                    {expiredDoc?.map((dataValue, index) => (
                      <div key={index} className="w-full">
                        <button
                          onClick={() => handleFilter("expiredDoc", dataValue)}
                          type="button"
                          className={`text-[13px] border-2 font-semibold tracking-wid w-full px-5 h-9 rounded-lg relative text-black border-black`}
                        >
                          {filterData?.expiredDoc?.includes(dataValue) && (
                            <div className="rounded-full bg-primary w-[11px] h-[11px] absolute top-1/2 left-2 transform -translate-y-1/2"></div>
                          )}
                          {dataValue}
                        </button>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-col gap-4">
              <div className="font-bold flex items-center ">
                <div>
                  Limited viewing in connection screen to all combinations or
                  either:
                </div>
                <div className="text-[#00000096] !ml-1">
                  <Tooltip
                    color="black"
                    placement="bottom"
                    overlayStyle={{
                      maxWidth: "400px",
                      fontWeight: "400",
                    }}
                    title={
                      <div
                        dangerouslySetInnerHTML={{
                          __html: `<div>
                          <div class='font-semibold text-base'>In-Scope and Out-of-Scope Settings</div>
                          <br/>
                          <div class='font-thin'>The toggles for in-scope and out-of-scope settings determine the scope of connections displayed. By default, both in-scope and out-of-scope connections are shown. These settings are fixed until the user decides to change them, allowing for consistent filtering based on the user's requirements.</div>
                          </div>`,
                        }}
                      />
                    }
                  >
                    <FiInfo />
                  </Tooltip>
                </div>
              </div>

              <div className="flex gap-3">
                <div className="bg-[#fff] w-full rounded-xl shadow-md p-5 flex gap-4 justify-between">
                  <div>
                    All connections{" "}
                    <FormSwitch
                      name="allConnections"
                      value={filterData.scope[0].includes("allConnections")}
                      onChange={() => handleFilter("scope", "allConnections")}
                    />
                  </div>
                  <div>
                    In-scope{" "}
                    <FormSwitch
                      name="inScope"
                      value={filterData.scope[0].includes("inScope")}
                      onChange={() => handleFilter("scope", "inScope")}
                    />
                  </div>
                  <div>
                    Out-of-scope{" "}
                    <FormSwitch
                      name="outOfScope"
                      value={filterData.scope[0].includes("outOfScope")}
                      onChange={() => handleFilter("scope", "outOfScope")}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="lg:w-[calc(100%-670px)] w-full flex flex-col gap-4">
            <>
              <div className="font-bold flex items-center">
                <div>Filter on any combination of connections:</div>
                <div className="text-[#00000096] ml-1">
                  <Tooltip
                    color="black"
                    // placement="leftTop"
                    overlayStyle={{
                      maxWidth: "400px",
                      fontWeight: "400",
                    }}
                    title={
                      <div
                        dangerouslySetInnerHTML={{
                          __html: `<div><div class='font-semibold text-base'>Department Tags</div><br/>
                           The department tags shown are created by the ${userType.mlro} in the Member Details Screen. These tags match any of the connections with the same access permissions set in the Manage Connection drawer. This allows for a more targeted and relevant filtering of connections based on department-specific criteria.</div>`,
                        }}
                      />
                    }
                  >
                    <FiInfo />
                  </Tooltip>
                </div>
              </div>
              <div className="flex gap-3 customeHeight">
                <div className="bg-[#fff] w-full rounded-xl shadow-md p-4 flex flex-col gap-3">
                  <div className="text-center mb-1 w-full font-bold">
                    Department tags
                  </div>

                  {isDataLoading ? (
                    <div className="w-full flex justify-center items-center">
                      <Spin />
                    </div>
                  ) : (
                    <div className="flex gap-[10px] flex-wrap flex-row overflow-auto">
                      {departmentData.map((value, index) => (
                        <button
                          key={index}
                          type="button"
                          // onClick={() => handleDepartmentClick(index)}
                          onClick={() => handleFilter("department", index)}
                          className={`text-[13px] border-2 font-semibold border-black tracking-wid w-auto px-6 h-9 rounded-lg relative`}
                        >
                          {filterData?.department?.includes(value) && (
                            <div
                              className="rounded-full bg-primary  w-[11px] h-[11px]
                            absolute top-1/2 left-2 transform -translate-y-1/2"
                            ></div>
                          )}
                          {value.department}
                        </button>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </>
          </div>
        </div>
      </Drawer>
    </>
  );
};

export default WhitelistedFilterDrawer;
