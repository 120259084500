import { Button, message, Spin } from "antd";
import axios from "axios";
import React, { useEffect } from "react";
import { useState } from "react";
import NotFoundData from "../components/NotFoundData";
import { baseUrl } from "../services/services.profile";
import { useUserContext } from "../contexts/userContext";
import CreateNewKybLinkDrawer from "../components/shared/Drawer/CreateNewKybLinkDrawer";
import AiViewerDrawer from "../components/shared/Drawer/AiViewerDrawer/aiViewerDrawer";

const ConnectionsOnboarding = () => {
  const { user, RoleManage, userData } = useUserContext();
  const [toggle, setToggle] = useState(true);
  const [addProfile, setAddProfile] = useState("");

  const removeAccess = [RoleManage?.MLRO, RoleManage?.ADMIN];

  const [onBoardingAll, setOnBoardingAll] = useState([]);
  const [onBoardingPending, setOnBoardingPending] = useState([]);
  const [openAiGrid, setOpenAiGrid] = useState(false);
  const [singleRecordData, setSingleRecordData] = useState({
    tokenId: "",
    companyName: "",
  });
  const [createNewKybLinkDrawerOpen, setCreateNewKybLinkDrawerOpen] =
    useState(false);

  const [loading, setLoading] = useState({
    all: false,
    internalChecking: false,
  });
  const switchName = toggle ? "internalChecking" : "all";
  const getOnBoardingApi = () => {
    setLoading((prev) => ({ ...prev, [switchName]: true }));
    const payload = {
      filter: {
        [switchName]: true,
      },
    };

    const apiCallUrl = axios.post(
      baseUrl + `/api/onboarding/all-list`,
      payload
    );

    apiCallUrl.then((res) => {
      // let array = res?.data?.data || [];
      // array = res?.data?.data?.filter((item) => item?.status !== "confirmed");
      let array = Array.isArray(res?.data?.data) ? res?.data?.data : [];
      array = array.filter((item) => item?.status !== "confirmed");
      toggle ? setOnBoardingPending(array) : setOnBoardingAll(array);
      setLoading((prev) => ({ ...prev, [switchName]: false }));
    });
  };

  const handleToggle = () => {
    setToggle(!toggle);
    setLoading({
      all: true,
      internalChecking: true,
    });
  };

  useEffect(() => {
    getOnBoardingApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggle]);

  const onBoardingLink = (item) => {
    const onBoardingbaseUrl = "https://clients.onboarding.kyb.pro";
    window.open(onBoardingbaseUrl + "/#/" + item?.token_id, "_blank");
  };

  const removeOnboarding = async (item) => {
    await axios
      .post(baseUrl + `/user/onboarding/delete`, {
        tokenId: item?.token_id,
      })
      .then(() => {
        message.success("Onboarding remove Successfully");
        getOnBoardingApi();
      })
      .catch(() => {
        message.error("Invalid Onboarding request");
      });
  };

  const sendReminderApi = async (item) => {
    await axios
      .post(baseUrl + `/user/onboarding/resendemail`, {
        tokenId: item?.token_id,
      })
      .then(() => {
        message.success("Email has been sent successfully");
        getOnBoardingApi();
      })
      .catch(() => {
        message.error("Invalid email");
      });
  };

  const renderOnBoardingList = (list, isLoading) => {
    return isLoading ? (
      <Spin className="w-full text-center mt-10" />
    ) : (
      <>
        {list?.length === 0 ? (
          <NotFoundData
            key="no data"
            message="No onboardings are currently taking place"
          />
        ) : (
          list?.map((item, index) => (
            <React.Fragment key={index}>
              <div
                className="w-full mb-6 flex-col sm:flex-row bg-[#f0f6fd] rounded-md p-4 flex justify-between gap-4"
                key={index}
              >
                <div className="flex flex-col text-[15px] justify-center items-start p-3">
                  <p className="font-bold mb-4">
                    {" "}
                    {item?.user_info?.email}{" "}
                    {item?.isSelfOnboard ? (
                      <>
                        {item?.user_info?.self_fname ||
                        item?.user_info?.self_lname
                          ? " - "
                          : ""}
                        {item?.user_info?.self_fname}
                        {item?.user_info?.self_fname &&
                          item?.user_info?.self_lname &&
                          " "}
                        {item?.user_info?.self_lname}
                      </>
                    ) : (
                      <>
                        {item?.user_info?.fname || item?.user_info?.lname
                          ? " - "
                          : ""}
                        {item?.user_info?.fname}
                        {item?.user_info?.fname &&
                          item?.user_info?.lname &&
                          " "}
                        {item?.user_info?.lname}
                      </>
                    )}
                  </p>

                  <p
                    className="underline text-primary hover:cursor-pointer break-all mb-1"
                    onClick={() => {
                      onBoardingLink(item);
                    }}
                  >
                    {`Your KYB Pro onboarding link:https://clients.onboarding.kyb.pro/#/${item?.token_id}`}
                    {/* Onboarding link */}
                  </p>
                  <div className="flex gap-1">
                    <p>requested on:</p>
                    <span className="font-semibold">
                      {item?.receivedInfo?.respResult?.date_created
                        ?.toString()
                        .slice(0, 16)}
                    </span>
                  </div>
                  <div className="flex gap-1 mb-2">
                    <p>Status:</p>
                    <span className="font-semibold">{item?.status}</span>
                  </div>

                  <>
                    {"poiVerifyToken" in item?.userCertificateData && (
                      <div className="flex gap-1">
                        {item?.userCertificateData?.poiVerifyToken ? (
                          <span className="font-semibold text-danger">
                            Company information update requested
                          </span>
                        ) : (
                          <span className="font-semibold text-[#70ad47]">
                            Company information update received
                          </span>
                        )}
                      </div>
                    )}
                    {"uboVerifyToken" in item?.userCertificateData && (
                      <div className="flex gap-1 mb-1">
                        {item?.userCertificateData?.uboVerifyToken ? (
                          <span className="font-semibold text-danger">
                            UBO information update requested
                          </span>
                        ) : (
                          <span className="font-semibold text-[#70ad47]">
                            UBO information update received
                          </span>
                        )}
                      </div>
                    )}

                    {"cddVerifyToken" in item?.userCertificateData && (
                      <div className="flex gap-1 mb-1">
                        {item?.userCertificateData?.cddVerifyToken ? (
                          <span className="font-semibold text-danger">
                            New CDD self-declaration information requested
                          </span>
                        ) : (
                          <span className="font-semibold text-[#70ad47]">
                            New CDD self-declaration information received
                          </span>
                        )}
                      </div>
                    )}
                  </>
                </div>

                <div className="flex flex-col justify-center gap-3 items-center text-[15px] px-5">
                  {/* Send ai grid button  */}
                  {item?.isInternalCheckingPending && (
                    <button
                      className="border bg-primary text-white px-3 whitespace-nowrap h-9 w-full  rounded-lg"
                      onClick={() => {
                        setOpenAiGrid(true);
                        setSingleRecordData({
                          tokenId: item?.token_id,
                          companyName: item?.user_info?.company?.name,
                        });
                      }}
                    >
                      AI Grid
                    </button>
                  )}

                  {/* Send reminder button  */}
                  <button
                    className="border border-[#86c1f4] text-primary px-3 whitespace-nowrap h-9 w-full  rounded-lg"
                    onClick={() => sendReminderApi(item)}
                  >
                    Send reminder
                  </button>

                  {/* Remove onboarding button  */}
                  {removeAccess.includes(user) && (
                    <button
                      className="border border-[#86c1f4] text-primary px-3 whitespace-nowrap h-9 w-full  rounded-lg"
                      onClick={() => removeOnboarding(item)}
                    >
                      Remove onboarding
                    </button>
                  )}
                </div>
              </div>
            </React.Fragment>
          ))
        )}
      </>
    );
  };

  return (
    <>
      <>
        <div className="flex flex-row-reverse flex-wrap mb-4 justify-between gap-4">
          <div className="flex justify-end items-center gap-3">
            <Button
              type="primary"
              className="text-xs border-primary flex cursor-pointer border-2 whitespace-nowrap font-semibold tracking-wide !text-white bg-primary px-4 h-8 rounded-lg"
              onClick={() => {
                setAddProfile("kyc");
                setCreateNewKybLinkDrawerOpen(true);
              }}
            >
              Add KYC Profile
            </Button>
            <Button
              type="primary"
              className="text-xs border-primary flex cursor-pointer border-2 whitespace-nowrap font-semibold tracking-wide !text-white bg-primary px-4 h-8 rounded-lg"
              onClick={() => {
                setAddProfile("kyb");
                setCreateNewKybLinkDrawerOpen(true);
              }}
            >
              Add KYB Profile
            </Button>
          </div>
          <div className="flex flex-1">
            <div className="flex flex-row justify-start items-center gap-5 whitespace-nowrap">
              <p className="text-xl font-bold">All</p>
              <label className="relative inline-flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  value=""
                  className="sr-only peer"
                  checked={toggle}
                  onChange={handleToggle}
                  readOnly
                />
                <div className="w-11 h-6 bg-blue-600 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600 "></div>
              </label>
              <p className="text-xl font-bold">Internal Checking Pending</p>
            </div>
          </div>
        </div>

        {toggle
          ? renderOnBoardingList(onBoardingPending, loading.internalChecking)
          : renderOnBoardingList(onBoardingAll, loading.all)}
      </>

      {createNewKybLinkDrawerOpen && (
        <CreateNewKybLinkDrawer
          createNewKybLinkDrawerOpen={createNewKybLinkDrawerOpen}
          setCreateNewKybLinkDrawerOpen={setCreateNewKybLinkDrawerOpen}
          getOnBoardingApi={getOnBoardingApi}
          type={addProfile}
        />
      )}

      {openAiGrid && (
        <AiViewerDrawer
          aiViewerDrawerOpen={openAiGrid}
          aiViewerDrawerClose={setOpenAiGrid}
          tokenId={singleRecordData?.tokenId}
          companyName={singleRecordData?.companyName}
          getOnBoardingApi={getOnBoardingApi}
          customerUi={false}
        />
      )}
    </>
  );
};

export default ConnectionsOnboarding;
