import axios from "axios";
import { baseUrl } from "./services.profile";

axios.defaults.headers.common["Authorization"] =
  "Bearer " + localStorage.getItem("token");
axios.defaults.withCredentials = true;

export const getPdfData = async (tokenId) => {
  const response = await axios.get(
    baseUrl + `/api/onboarding/get-doc?tokenId=${tokenId}`
  );
  return response.data;
};

export const getPreviousGridData = async (tokenId) => {
  const response = await axios.get(
    baseUrl + `/api/onboarding/get-grid-data?tokenId=${tokenId}`
  );
  return response.data;
};

export const getRequestMail = async (payload) => {
  const response = await axios.post(
    baseUrl + `/api/onboarding/request-le-doc`,
    payload
  );

  return response.data;
};

export const getRequestOSR = async (payload) => {
  const response = await axios.post(baseUrl + `/api/onboarding/`, payload);

  return response.data;
};

export const getGridData = async (payload) => {
  const response = await axios.post(
    baseUrl + `/api/onboarding/create-grid`,
    payload
  );
  return response.data;
};

export const saveGridData = async (payload) => {
  const response = await axios.post(
    baseUrl + `/api/onboarding/save-grid`,
    payload
  );
  return response.data;
};

export const setDefaultDoc = async (payload) => {
  const response = await axios.post(baseUrl + `/api/set-default-doc`, payload);
  return response.data;
};

export const getDocRequestMailTemplate = async (tokenId, docType) => {
  const response = await axios.get(
    baseUrl + `/api/get-mail-template/?docType=${docType}&token_id=${tokenId}`
  );
  return response.data;
};
export const getDocRequestHistory = async (tokenId, docType) => {
  const response = await axios.get(
    baseUrl + `/api/get-mail-body/?token_id=${tokenId}`
  );
  return response.data;
};

export const createOCRToPdf = async (payload) => {
  const response = await axios.post(
    baseUrl + `/api/onboarding/create-pdf-ocr`,
    payload
  );
  return response.data;
};

export const deleteOCRPdf = async (payload) => {
  const response = await axios.post(
    baseUrl + `/api/onboarding/delete-pdf-ocr`,
    payload
  );
  return response.data;
};
