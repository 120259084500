import React, { useEffect, useState } from "react";
import { Button, Drawer, Form, message } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import "./createNewKybLinkDrawer.css";
import { FormInputField } from "../../../core/CustomFormFields";
import { Formik } from "formik";
import * as Yup from "yup";
import { getAdminProfile } from "../../../../services/services.profile";
import { createNewLink } from "../../../../services/connection";

const CreateNewKybLinkDrawer = ({
  createNewKybLinkDrawerOpen,
  setCreateNewKybLinkDrawerOpen,
  getOnBoardingApi,
  type,
}) => {
  const [isLoading, setLoadings] = useState(false);
  const [teamMemberDetail, setTeamMemberDetail] = useState({
    email: "",
    fName: "",
    lName: "",
  });
  const [profile, setProfile] = useState();

  useEffect(() => {
    getAdminProfile().then((adminRes) => {
      if (adminRes?.data) {
        setProfile(adminRes?.data);
      }
    });
  }, []);

  // Submit function
  const handleAddLinkSubmitButton = (values, { resetForm }) => {
    setLoadings(true);
    const payload = {
      owner_username: profile?.username,
      email: values?.email,
      first_name: values?.fName,
      last_name: values?.lName,
      onboardType: type,
    };

    createNewLink(payload)
      .then((res) => {
        if (res?.error) {
          throw res?.error;
        }
        message.success("Self Onboarding Link created successfully");
        resetForm();
        setCreateNewKybLinkDrawerOpen(false);
        getOnBoardingApi();
      })
      .catch((err) => {
        message.error(
          "Something went wrong creating self onboarding link, please try again later"
        );
      })
      .finally(() => {
        setLoadings(false);
      });
  };

  // Set form validation
  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Email is required"),
    fName: Yup.string().required("First name is required"),
    lName: Yup.string().required("Last name is required"),
  });

  return (
    <Formik
      enableReinitialize={true}
      initialValues={teamMemberDetail}
      onSubmit={handleAddLinkSubmitButton}
      validationSchema={validationSchema}
    >
      {({ values, handleChange, handleSubmit, errors, touched, resetForm }) => (
        <Drawer
          placement="right"
          title={
            <div className="flex justify-between items-center pt-2 px-5">
              <p className="text-xl font-bold">
                Create New {type?.toUpperCase()} Link
              </p>
              <CloseOutlined
                className="!text-lg"
                onClick={() => {
                  resetForm();
                  setCreateNewKybLinkDrawerOpen(false);
                }}
              />
            </div>
          }
          className="createNewKybLinkDrawer"
          closable={false}
          footer={
            <div className="flex justify-end items-center gap-3">
              <Button
                type="primary"
                ghost
                onClick={() => {
                  resetForm();
                  setCreateNewKybLinkDrawerOpen(false);
                }}
              >
                Cancel
              </Button>
              <Button
                className="capitalize flex"
                htmlType="submit"
                type="primary"
                loading={isLoading}
                disabled={isLoading}
                onClick={(event) => {
                  event.preventDefault();
                  handleSubmit();
                }}
              >
                Create Link
              </Button>
            </div>
          }
          onClose={() => {
            resetForm();
            setCreateNewKybLinkDrawerOpen(false);
          }}
          open={createNewKybLinkDrawerOpen}
        >
          <Form layout="vertical">
            <div className="w-full py-3 px-2 flex flex-col gap-5">
              <div className="bg-[#fff] p-5 flex flex-col gap-5 rounded-xl shadow-md">
                <div className="flex flex-col gap-1">
                  <p className="text-[#000] font-extrabold text-sm flex gap-1">
                    <span className="text-red-500">*</span> Email
                  </p>
                  <FormInputField
                    name="email"
                    value={values?.email}
                    onChange={handleChange}
                    placeholder="Email of the Connection"
                  />
                  {errors?.email && touched?.email && (
                    <div className="text-red-500 text-xs font-bold">
                      {errors?.email}
                    </div>
                  )}
                </div>
                <div className="flex flex-col gap-1">
                  <p className="text-[#000] font-extrabold text-sm flex gap-1">
                    <span className="text-red-500">*</span>First name
                  </p>
                  <FormInputField
                    name="fName"
                    value={values?.fName}
                    onChange={handleChange}
                    placeholder={"First name of the Connection"}
                  />
                  {errors?.fName && touched?.fName && (
                    <div className="text-red-500 text-xs font-bold">
                      {errors?.fName}
                    </div>
                  )}
                </div>
                <div className="flex flex-col gap-1">
                  <p className="text-[#000] font-extrabold text-sm flex gap-1">
                    <span className="text-red-500">*</span>Last name
                  </p>
                  <FormInputField
                    name="lName"
                    value={values?.lName}
                    onChange={handleChange}
                    placeholder={"Last name of the Connection"}
                  />
                  {errors?.lName && touched?.lName && (
                    <div className="text-red-500 text-xs font-bold">
                      {errors?.lName}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Form>
        </Drawer>
      )}
    </Formik>
  );
};

export default CreateNewKybLinkDrawer;
